@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
   /* Hide scrollbar for Chrome, Safari and Opera */
   .no-scrollbar::-webkit-scrollbar {
       display: none;
   }
  /* Hide scrollbar for IE, Edge and Firefox */
   .no-scrollbar {
       -ms-overflow-style: none;  /* IE and Edge */
       scrollbar-width: none;  /* Firefox */
 }
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

/* .main1owner {
  background-image: url('./components/assets/mountainsG.jpeg');
  background-size: cover;
} */

.slick-dots {
   position: absolute;
   top: calc(100% - 70px);
   pointer-events: none;
   @media (min-width: 768px) {
      top: calc(100% - 45px);
   }
}

.main1owner .slick-track {
   height: 85vh;
}

.innerslider .slick-track {
   display: flex !important;
   height: 100%;
}
.innerslider .slick-list {
   height: 100%;
}
.innerslider .slick-slide {
   height: 100%;
}
.innerslider .slick-slide > div {
   height: 100%;
}

/* Custom CSS in your global styles.css or within a <style> tag */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px; /* Adjust thumb size */
    height: 20px; /* Adjust thumb size */
    background: #665926; /* Thumb background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%; /* Round shape */
    margin-top: -9px; /* Adjust for centering */
  }

input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  width: 20px; /* Adjust thumb size */
  height: 20px; /* Adjust thumb size */
  background: #665926; /* Thumb background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Round shape */
  overflow: visible;

  
}

.backg-login {
   background-image: url('../public/images/LearjetInterior.webp');
   background-size: cover;
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-position: center;
   opacity: 0.9;
}

.aboutus {
   background-image: url('../public/images/aboutus_image.jpg');
   background-size: cover;
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-position: center;
   opacity: 1;
}

.aboutusbg2 {
   background-image: url('../public/images/foggy_forest.jpg');
   background-size: cover;
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-position: center;
   opacity: 1;
}