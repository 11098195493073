/* LandingPage.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.heroLanding {
  background: url('../../../public/images/hero-image.png') no-repeat center center;
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-contentLanding {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  max-width: 600px;
  text-align: center;
}

.landing-pageLanding {
  font-family: 'Arial', sans-serif;
}

.navbarLanding {
  background-color: #333;
  color: white;
  padding: 40px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-formLanding {
  display: flex;
  gap: 10px;
}

.form-controlLanding input {
  padding: 5px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.search-buttonLanding {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 3px;
}

.search-buttonLanding:hover {
  background-color: darkred;
}

.form-controlLanding select {
  padding: 5px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: white;
  color: black;
}

.form-controlLanding input[type="date"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.btonLanding {
  width: 150px;
  height: 50px;
  border-radius: 7px;
  border: none;
  transition: all 0.5s ease-in-out;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: #040f16;
  color: #f5f5f5;
}

.btonLanding:hover {
  box-shadow: 0 0 20px 0px #2e2e2e3a;
}

.btonLanding .iconAvLanding {
  position: absolute;
  height: 40px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.btonLanding .textLanding {
  transform: translateX(55px);
}

.btonLanding:hover .iconAvLanding {
  width: 175px;
}

.btonLanding:hover .textLanding {
  transition: all 0.5s;
  opacity: 0;
}

.btonLanding:focus {
  outline: none;
}

.btonLanding:active .iconAvLanding {
  transform: scale(0.85);
}