// Import font to use
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Spartan:wght@300;400;500&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Spartan', sans-serif;

}

// These are my defined variables to be used in the CSS code later as variables
:root {
        --PrimaryColor: hsl(40, 93%, 22%);
        --SecondaryColor: #0d0d0d;
        --colorOne: hsl(15, 74%, 70%);
        --colorTwo: hsl(28, 100%, 82%);
        --HoverColor: hsl(44, 55%, 39%);
        --whiteColor: hsl(0, 0%, 100%);
        --blackColor: hsl(0,0%, 12%);
        --textColor: hsl(240, 1%, 48%);
        --borderColor: hsl(0, 0%, 0%);
        --whiteColorDeam: hsl(0,0%,93%);
        --greyBg: hsl(0, 0%, 96%);
        --greyText: rgb(145, 145, 145);
        --inputColor: hsl(330, 12%, 97%);
        --boxShadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        --transition: .4s ease-in-out;
}

//Defalut code styling:

body {
    background: var(--whiteColor);
}

a {
    text-decoration: none;
}

li {
    list-style: none;
    cursor: pointer;
}

.sectionLanding {
    padding: 4rem 0 2rem
}

.containerLanding {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.iconLanding {
    font-size: 1.3rem;
    cursor: pointer;
}


.flexLanding {
    display: flex;
    align-items: center;
}
.gridLanding {
    display: grid;
    align-items: center;
}

.noneLanding {
    display: none;
}


.btnLanding {
    padding: .7rem 1.5rem;
    background: var(--PrimaryColor);
    border: none;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
    color: var(--whiteColor);
    a {
        color: var(--whiteColor);
        font-weight: 500;
    }
    &:hover{
        background: var(--HoverColor);
    }
}

.imgLanding, .videoLanding{
    width: 100%;
    height: auto;
}

input{
    font-size:100%;
    color: var(--greyText);
}
p{
    font-size: 13px;
    color: var(--greyText);
    line-height: 22px;
}
h4 {
    padding: 1rem 0;
    font-weight: 700;
    color: var(--blackColor)
}

h2 {
    font-size: 25px;
    padding: 1rem 0;
    color: var(--blackColor)
}

//NavBar Styling

.navBarLanding {
    position: fixed;
    flex-direction: column;
    width: 100%;
    z-index: 1000;

    .navBarOneLanding, .navBarTwoLanding{
        justify-content: space-between;
        padding: .9rem;
        width: 100%;
    }

    .navBarOneLanding{
        background: var(--SecondaryColor);
        color: var(--whiteColor);

    
        .iconLanding:hover{
            color: var(--whiteColor)
        }

        .atbLanding{
            gap: 1rem;

            span{
                color:var(--whiteColor);
                font-size: 13px;
                cursor: pointer;
    
                 &:hover{
                    color: var(--PrimaryColor);
                    font-size: 700;
                }
            }
        }
        
        
    }

    .navBarTwoLanding{
       display: flex; 
        align-items: center;
        justify-content: space-between;
        top: 3rem;
        padding: 1.5rem 1rem;
        position: fixed;
        transition: .3s ease-in-out;

        .logoDivLanding{
            .LogoLanding{
                width: 13%;
            }
        }
        @media screen and (max-width: 768px) {
            .navBarMenuLanding{
                position: absolute;
                background: var(--SecondaryColor);
                padding: 1.5rem;
                border-radius: 10px;
                top: 5rem;
                right: -50%;
                width: 80%;
                transform: translateX(50%);
                z-index: 2000;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
                border: 3px solid var(--SecondaryColor);
                transition: .3s ease-in-out;

                .menuLanding{
                    flex-direction: column;
                    gap: .5rem;
                    text-align: center;
        
                    .listItemLanding{
                        padding: .5rem;
                        cursor: pointer;
                        color: var(--whiteColor);

                        &:hover{
                            color:var(--HoverColor);
                            font-weight: 700;
                        }
                    }
                }

                .btnOneLanding{
                    display: block;
                    justify-content: center;
                    margin: auto;
                    margin-top: 1rem;
                }
            }

            .btnTwoLanding{
                display: none;
            }
        }
        .toggleIconLanding{
            .iconLanding{
                font-size: 30px;
                color: var(--blackColor);

            }
        }

        .showNavBarLanding{
            right: 50%;
            transition: .5s ease-in-out;
        }
    }

    .navbar_With_BgLanding{
        background: var(--whiteColorDeam);
        padding: .5rem 1rem;
        box-shadow:  rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

    }
}

// Home Section Styling
.homeLanding{
    padding-top: 10rem;
    text-align: center;
    gap: 2rem;
    flex-direction: column;

    .mainTextLanding{
        color: var(--blackColor);
        font-weight: 700;
        font-size: 15px;
        line-height: 2.5rem;
    }

    .homeImagesLanding{
        width: 100%;
        margin: 1rem auto 0; //Will change on wider screens
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 5rem;

        .backgroundImgLanding{
            width: 100%;
            img{
                border-radius: 7rem;
                width: 90%;
            }
        }

        .planeLanding{
            position: absolute;
            width: 100%;
            top: -3%;
        }
    }
}

//Search Section Styling
.searchLanding{
    
    .sectionContainerLanding{
        margin-top: 6rem;
        width: 100%;
        padding: 1.5rem;
        border: 2px solid var(--greyBg);
        border-radius: 16px;
        row-gap: 2rem;

        .btnsLanding{
            background: var(--SecondaryColor);
            padding: .5rem;
            border-radius: 1rem;
            flex-direction: column;
            width: 100%;
            margin: auto;
            color: var(--whiteColor);

            .singleBtnLanding{
                padding: .8rem 1.5rem;
                font-size: 13px;
                border-radius: 3rem;
                width: 80%;
                text-align: center;

                &:hover{
                    background: var(--PrimaryColor);
                    font-weight: 500;
                    transition: .4s ease;
                }
            }

            .activeLanding{
                background: var(--PrimaryColor);
                color: var(--whiteColor);
                font-weight: 500;
            }
        }

        .searchInputsLanding{
            width: 100%;
            justify-content: center;
            flex-direction: column;
            gap: 1.5rem;


            .singleInputLanding{
                    .iconDivLanding{
                        padding: 16px;
                        border-radius: 3rem;
                        background: var(--SecondaryColor);
                        margin-right: 1rem;

                        .iconLanding{
                            color: var(--whiteColor);
                        }
                    }

                    .textsLanding{
                        h4{
                            padding-top: 0;
                            padding-bottom: .7rem;
                            color: var(--blackColor);
                            font-weight: 700;
                        }

                        input{
                            width: 95%;
                            border: none;
                            outline: none;
                            background: transparent;

                            &::placeholder{
                                font-size: 12px;
                                opacity: .5x;
                            }
                        }
                    }

            }
        }
    }
}

//Guarantee Section Styling
.garantiaLanding{
    .sectionContainerLanding{
        .titlesDivLanding{
            text-align: center;
            small{
                letter-spacing: .8rem;
                text-transform: uppercase;
                font-size: 13px;
                color: var(--blackColor);
                line-height: 1.5rem; 
            }
        }

        .infoDivLanding{
            margin-top: 2rem;
            gap: 1.5rem;

            .textDivLanding{
                gap:1.5rem;

                .singleInfoLanding{
                    .numberLanding{
                        background: var(--PrimaryColor);
                        padding: 5px 12px;
                        width: 40px;
                        border-radius: 3rem;
                        text-align: center;
                        font-size: 13px;
                        font-weight: 700;
                        color: var(--whiteColor);
                    }
                }
            }

            .imgDivLanding{
                margin: auto;
                img{
                    width: 100%;
                    max-width: 400px;
                }
            }
        }
    }
}

//Aeronaves Section Styling
.aeronavesLanding{
    background: var(--SecondaryColor);
    color: var(--whiteColor);
    h2{
        color: var(--whiteColor);
    }
    .titleDivLanding{
        flex-direction: column;
        text-align: center;
        gap: 1.5rem;
        justify-content: space-between;
    }

    .cardsDivLanding{
        gap: 1.5rem;
        margin-top: 3rem;

        .singleCardLanding{
            padding: 3rem 2rem;
            text-align: center;
            
            img{
                border-radius: 10rem;
                gap: 1rem;
                margin: auto;
                height: 320px;
                width: 90%;
                margin-bottom: 2rem;
            }
        }
    }
    
    
}


//Footer Section Styling
.footerLanding{
    background: var(--SecondaryColor);
    color: var(--whiteColor);

    .sectionContainerLanding{
        gap: 2.5rem;

        .gridOneLanding{
            gap: 1rem;
            justify-content: center;
            margin: auto;
        

            .logoDivLanding{
                .LogoLanding{
                    width: 20%;
                }
            }
            .socialIconLanding{
                margin-top: 1rem;
                gap: 1rem;

                .iconLandingLanding{
                    height: 50px;
                    width: 30px;
                    border-radius: 10rem;
                    padding: .4rem;

                    &:hover{
                        background: var(--PrimaryColor);
                        transform: translateY(-7px);
                        transition: var(--transition);
                    }
                }
            }
        }   
    
        .footerLinksLanding{
            display: grid;

            .linkTitleLanding{
                color: var(--whiteColor);
                font-weight: 500;
                display: block;
                padding-bottom: 1.3rem;
            }

            li a{
                display: block;
                color: var(--greyText);
                transition: var(--transition);
                padding-bottom: .3rem;

                &:hover{
                    transform: translateX(7px);
                    color: var(--PrimaryColor);

                }
            }
        }
    }

    .copyRightdivLanding{
        justify-content: center;
        margin-top: 1.5rem;
        padding: 1rem;
        gap: 1rem;
        border-top: 1px solid var(--greyText);
        text-align: center;
    }
}

//Media Screen Styling
@media screen and (min-width: 480px){
    //Home
    .homeLanding{
        padding-top: 3rem;
    }

    .searchLanding{
        padding-top: 5rem;
        .sectionContainerLanding{
            margin-top: 6rem;
            .searchInputsLanding{
                display: grid;
                grid-template-columns: repeat(2,1fr);
            }
        }
    }

    .footerLanding{
        .sectionContainerLanding{
            grid-template-columns: repeat(2,1fr);
        }
    }
    

}

@media screen and (min-width: 570px){
    //Home
    .homeLanding{
        .mainTextLanding{
            width: 80%;
        }

        .homeImagesLanding{
            .backgroundImgLanding{
                border-radius: 15rem;
            }

            .planeLanding{
                position: absolute;
                width: 95%;
                top: -8%;
            }
        }
    }


    //Buscar
    .searchLanding{
        padding-top: 5rem;
        .sectionContainerLanding{
            margin-top: 6rem;
            .btnsLanding{
                border-radius: 3rem;
                flex-direction: row;
                justify-content: center;
            }
        }
    }

    //Calidad
    .garantiaLanding{
        .sectionContainerLanding{
            .infoDivLanding{
                .textDivLanding{
                    margin-top: 2rem;
                    grid-template-columns: repeat(2,1fr);
                }
            }
        }
    }

    //Aeronaves
    .aeronavesLanding{
        .aeronavesContainerLanding{
            .titleDivLanding{
                flex-direction: row;
                h2{
                    text-align: left;
                    max-width: 500px;
                }
            }

            .cardsDivLanding{
                grid-template-columns: repeat(2,1fr);
            }
        }
    }

    .footerLanding{
        .sectionContainerLanding{
            padding-top: 2rem;
            grid-template-columns: repeat(3,1fr);

        }
    }
}

@media screen and (min-width: 680px){
    //Home
    .homeLanding{
        .mainTextLanding{
            width: 80%;
        }

        .homeImagesLanding{
            .backgroundImgLanding{
                width: 80%;
                .bgImLanding{
                    height: 100%;
                    width: 100%;
                    border-radius: 15rem;
                }
            }

            .planeLanding{
                width: 95%;
                top: -8%;
            }
        }
    }
    //Search
    .searchLanding{
        padding-top: 5rem;
        .sectionContainerLanding{
            margin-top: 6rem;
            .btnLandingLanding{
                border-radius: 3rem;
                flex-direction: row;
                justify-content: center;
            }
            .searchInputsLanding{
                grid-template-columns: repeat(3,1fr);
            }
        }
    }

    //Footer
    .footerLanding{
        .sectionContainerLanding{
            grid-template-columns: repeat(4,1fr);
        }
    }
}

@media screen and (min-width: 768px){
    //NavBar
    .navBarLanding{
        align-items: center;
        .navBarOneLanding{
            .noneLanding{
                display: flex;
                gap: 1.5rem;

                li{
                    font-size: 13px;
                    gap: .5rem;
                    color: var(--textColor);
                    
                    &:hover{
                        color: var(--PrimaryColor);
                    }
                }
            }
        }

        .navBarTwoLanding{
            justify-content: space-between;
            align-items: center;

            .logoDivLanding{
                .LogoLanding{
                    max-width: 20%;
                }
            }
            
            .menuLanding{
                flex-direction: row;
                gap: 1rem;
                margin-right: 10rem;

                .listItemLanding{
                    padding: 0 .5rem;
                    font-size: 14px;
                    cursor: pointer;
                    font-weight: 500;
                    white-space: nowrap;
                    

                    &:hover{
                        color: var(--PrimaryColor);
                        font-weight: 700;
                    }

                }
            }

            .btnOneLanding, .toggleIconLanding{
                display: none;
            }
        }

        .navbar_With_BgLanding{
            padding: 1rem;
        }
    }

    //Search
    .searchLanding{
        padding: 1.5rem;
        .sectionContainerLanding{
            margin-top: 0.3rem;
            justify-content: center;
            .btnsLanding{
                width: 70%;
                border-radius: 3rem;
                flex-direction: row;
            }
            .searchInputsLanding{
                grid-template-columns: repeat(4,1fr);

                .singleInputLanding{
                    .textsLanding{
                        input{
                            width: 100%;
                        }

                    }
                }
            }
        }
    }

    //Garantia
    .garantiaLanding{
        padding: 8rem;
        .sectionContainerLanding{
            .infoDivLanding{
                grid-template-columns: repeat(2,1fr);
                gap: 2rem;
                margin-top: 5rem;
                .textDivLanding{
                    margin-top: 0rem;
                    grid-template-columns: repeat(1,1fr);
                }
            }
        }
    }

    //Areonaves
    .aeronavesLanding{
        padding: 5rem;
        .aeronavesContainerLanding{
            padding: 0 6rem;
            .titleDivLanding{
                width: 90%;
                margin: auto;
                align-items: center;
            }

            .cardsDivLanding{
                grid-template-columns: repeat(3,1fr);

                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }


}

@media screen and (min-width: 960px){
    //home
    .homeLanding{
        padding-top: 1.5rem;
        .mainTextLanding{
            width: 70%;
            padding-bottom: 1.5rem;
            font-size: 22px;
            line-height: 4rem;
        }
        
        .homeImagesLanding{
            width: 80%;
            height: auto;
        }
    }

    .searchLanding{
        .sectionContainerLanding{
            margin-top: 6rem;
            .searchInputsLanding{
                grid-template-columns: repeat(4,1fr);

                
                }
            }
        }
}

@media screen and (min-width: 1024px){
    p{
        font-size: 15px;
        color: var(--greyText);
        line-height: 25px;
    }
    h4 {
        font-size: 15px;
    }
    
    h2 {
        font-size: 24px;
    }

    .sectionLanding {
        padding: 6rem 0 4rem;
    }
    
    .containerLanding {
        width: 75%;
        margin: auto;
    }

    .navBarLanding{
        .navBarOneLanding, .navBarTwoLanding{
            padding: 1.5rem 5rem;

        }

        .navBarTwoLanding{
            margin-top: 1rem;
        }

        .navbar_With_BgLanding{
            padding: 1rem 5rem;
            margin-top: 1rem;
        }
    }

    .homeLanding{
        .mainTextLanding{
            width: 60%;
            font-size: 22px;
            line-height: 4rem;
        }
        
        .homeImagesLanding{
            .backgroundImgLanding{
            width: 90%;
            height: 300px;

                .bgImLanding{
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 15rem;
                }
            }

            .planeLanding{
                width: 100%;
                top: -10%;
            }
        }
    }

    .searchLanding{
        padding-top: 2rem;
        .sectionContainerLanding{
            margin-top: 1rem;
            .btnsLanding{
                width: 50%;
            }

        }
    }
}

@media screen and (min-width: 1332px){
    .navBarLanding{
        .navBarTwoLanding{
            .menuLanding{
                gap: 1rem;

                .listItemLanding{
                    padding: 0 1.5rem;
                    font-size: 14px;
                }
            }
        }
    }

    .footerLanding{
        .sectionContainerLanding{
            grid-template-columns: repeat(5,1fr);
            font-size: smaller;
        }
    }
}