/* Header.css */
.headerHeader {
    position: sticky;
    top: 0;
    padding: 3px 20px; 
    background-color: #0d0d0d; 
    display: flex; 
    justify-content: flex-start;
    align-items: center; 
    z-index: 1000;
    font-size: 12px;


    
}
header {
    position: relative;
    padding: 20px;
    background: #0d0d0d;
    color: #F2F2F2;
    display: flex;
    justify-content: center;
    z-index: 1;
  }
  
  header a {
    color: #F2F2F2;
    padding: 0 15px;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  
  
.logoHeader {
    height: 70px; 
    
    
}

.btnSninHeader {
    font-size: 12px;
    background: transparent;
    border: none;
    padding: 1em 1.5em;
    color: #f2f2f2;
    text-transform: uppercase;
    position: relative;
    transition: 0.5s ease;
    cursor: pointer;
    margin-left: auto !important;
    padding: 10px 20px;
  }
  
  .btnSninHeader::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: #665206;
    transition: 0.5s ease;
  }
  
  .btnSninHeader:hover {
    color: #F2F2F2;
    transition-delay: 0.5s;
  }
  
  .btnSninHeader:hover::before {
    width: 100%;
  }
  
  .btnSninHeader::after {
    position: relative;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color: #665206;
    transition: 0.4s ease;
    z-index: -1;
  }
  
  .btnSninHeader:hover::after {
    height: 100%;
    transition-delay: 0.4s;
    color: #F2F2F2;
  }
  .linkHead {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    padding: 0.5rem;
    position: relative;
  }
  
  .linkHead::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #665206;
    border-radius: 5px;
    transform: scaleX(0);
    transition: all 0.6s ease;
  }
  
  .linkHead::before {
    bottom: 0;
    left: 0;
  }
  
  .linkHead:hover::before {
    transform: scaleX(1);
  }
  
  
/* Botón de navegación para dispositivos móviles */
/* Botón de navegación para dispositivos móviles */
  .nav-btnHeader {
    display: none; /* Oculto por defecto */
    font-size: 2rem;
    background: none;
    border: none;
    color: #F2F2F2;
    cursor: pointer;
  }

@media (max-width: 768px) {
  .nav-btnHeader {
      display: block;
  }

  .headerHeader nav, nav.nav-hiddenHeader {
      display: none;
  }

  nav.nav-visibleHeader {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: 0;
      background-color: #0d0d0d;
      align-items: center;
      justify-content: center;
      
  }
}