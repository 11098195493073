@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Spartan:wght@300;400;500&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Spartan", sans-serif;
}

:root {
  --PrimaryColor: hsl(40, 93%, 22%);
  --SecondaryColor: #0d0d0d;
  --colorOne: hsl(15, 74%, 70%);
  --colorTwo: hsl(28, 100%, 82%);
  --HoverColor: hsl(44, 55%, 39%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0,0%, 12%);
  --textColor: hsl(240, 1%, 48%);
  --borderColor: hsl(0, 0%, 0%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(145, 145, 145);
  --inputColor: hsl(330, 12%, 97%);
  --boxShadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  --transition: .4s ease-in-out;
}

body {
  background: var(--whiteColor);
}

a {
  text-decoration: none;
}

li {
  list-style: none;
  cursor: pointer;
}

.sectionLanding {
  padding: 4rem 0 2rem;
}

.containerLanding {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.iconLanding {
  font-size: 1.3rem;
  cursor: pointer;
}

.flexLanding {
  display: flex;
  align-items: center;
}

.gridLanding {
  display: grid;
  align-items: center;
}

.noneLanding {
  display: none;
}

.btnLanding {
  padding: 0.7rem 1.5rem;
  background: var(--PrimaryColor);
  border: none;
  outline: none;
  border-radius: 3rem;
  cursor: pointer;
  color: var(--whiteColor);
}
.btnLanding a {
  color: var(--whiteColor);
  font-weight: 500;
}
.btnLanding:hover {
  background: var(--HoverColor);
}

.imgLanding, .videoLanding {
  width: 100%;
  height: auto;
}

input {
  font-size: 100%;
  color: var(--greyText);
}

p {
  font-size: 13px;
  color: var(--greyText);
  line-height: 22px;
}

h4 {
  padding: 1rem 0;
  font-weight: 700;
  color: var(--blackColor);
}

h2 {
  font-size: 25px;
  padding: 1rem 0;
  color: var(--blackColor);
}

.navBarLanding {
  position: fixed;
  flex-direction: column;
  width: 100%;
  z-index: 1000;
}
.navBarLanding .navBarOneLanding, .navBarLanding .navBarTwoLanding {
  justify-content: space-between;
  padding: 0.9rem;
  width: 100%;
}
.navBarLanding .navBarOneLanding {
  background: var(--SecondaryColor);
  color: var(--whiteColor);
}
.navBarLanding .navBarOneLanding .iconLanding:hover {
  color: var(--whiteColor);
}
.navBarLanding .navBarOneLanding .atbLanding {
  gap: 1rem;
}
.navBarLanding .navBarOneLanding .atbLanding span {
  color: var(--whiteColor);
  font-size: 13px;
  cursor: pointer;
}
.navBarLanding .navBarOneLanding .atbLanding span:hover {
  color: var(--PrimaryColor);
  font-size: 700;
}
.navBarLanding .navBarTwoLanding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 3rem;
  padding: 1.5rem 1rem;
  position: fixed;
  transition: 0.3s ease-in-out;
}
.navBarLanding .navBarTwoLanding .logoDivLanding .LogoLanding {
  width: 13%;
}
@media screen and (max-width: 768px) {
  .navBarLanding .navBarTwoLanding .navBarMenuLanding {
    position: absolute;
    background: var(--SecondaryColor);
    padding: 1.5rem;
    border-radius: 10px;
    top: 5rem;
    right: -50%;
    width: 80%;
    transform: translateX(50%);
    z-index: 2000;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border: 3px solid var(--SecondaryColor);
    transition: 0.3s ease-in-out;
  }
  .navBarLanding .navBarTwoLanding .navBarMenuLanding .menuLanding {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }
  .navBarLanding .navBarTwoLanding .navBarMenuLanding .menuLanding .listItemLanding {
    padding: 0.5rem;
    cursor: pointer;
    color: var(--whiteColor);
  }
  .navBarLanding .navBarTwoLanding .navBarMenuLanding .menuLanding .listItemLanding:hover {
    color: var(--HoverColor);
    font-weight: 700;
  }
  .navBarLanding .navBarTwoLanding .navBarMenuLanding .btnOneLanding {
    display: block;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
  }
  .navBarLanding .navBarTwoLanding .btnTwoLanding {
    display: none;
  }
}
.navBarLanding .navBarTwoLanding .toggleIconLanding .iconLanding {
  font-size: 30px;
  color: var(--blackColor);
}
.navBarLanding .navBarTwoLanding .showNavBarLanding {
  right: 50%;
  transition: 0.5s ease-in-out;
}
.navBarLanding .navbar_With_BgLanding {
  background: var(--whiteColorDeam);
  padding: 0.5rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.homeLanding {
  padding-top: 10rem;
  text-align: center;
  gap: 2rem;
  flex-direction: column;
}
.homeLanding .mainTextLanding {
  color: var(--blackColor);
  font-weight: 700;
  font-size: 15px;
  line-height: 2.5rem;
}
.homeLanding .homeImagesLanding {
  width: 100%;
  margin: 1rem auto 0;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5rem;
}
.homeLanding .homeImagesLanding .backgroundImgLanding {
  width: 100%;
}
.homeLanding .homeImagesLanding .backgroundImgLanding img {
  border-radius: 7rem;
  width: 90%;
}
.homeLanding .homeImagesLanding .planeLanding {
  position: absolute;
  width: 100%;
  top: -3%;
}

.searchLanding .sectionContainerLanding {
  margin-top: 6rem;
  width: 100%;
  padding: 1.5rem;
  border: 2px solid var(--greyBg);
  border-radius: 16px;
  row-gap: 2rem;
}
.searchLanding .sectionContainerLanding .btnsLanding {
  background: var(--SecondaryColor);
  padding: 0.5rem;
  border-radius: 1rem;
  flex-direction: column;
  width: 100%;
  margin: auto;
  color: var(--whiteColor);
}
.searchLanding .sectionContainerLanding .btnsLanding .singleBtnLanding {
  padding: 0.8rem 1.5rem;
  font-size: 13px;
  border-radius: 3rem;
  width: 80%;
  text-align: center;
}
.searchLanding .sectionContainerLanding .btnsLanding .singleBtnLanding:hover {
  background: var(--PrimaryColor);
  font-weight: 500;
  transition: 0.4s ease;
}
.searchLanding .sectionContainerLanding .btnsLanding .activeLanding {
  background: var(--PrimaryColor);
  color: var(--whiteColor);
  font-weight: 500;
}
.searchLanding .sectionContainerLanding .searchInputsLanding {
  width: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}
.searchLanding .sectionContainerLanding .searchInputsLanding .singleInputLanding .iconDivLanding {
  padding: 16px;
  border-radius: 3rem;
  background: var(--SecondaryColor);
  margin-right: 1rem;
}
.searchLanding .sectionContainerLanding .searchInputsLanding .singleInputLanding .iconDivLanding .iconLanding {
  color: var(--whiteColor);
}
.searchLanding .sectionContainerLanding .searchInputsLanding .singleInputLanding .textsLanding h4 {
  padding-top: 0;
  padding-bottom: 0.7rem;
  color: var(--blackColor);
  font-weight: 700;
}
.searchLanding .sectionContainerLanding .searchInputsLanding .singleInputLanding .textsLanding input {
  width: 95%;
  border: none;
  outline: none;
  background: transparent;
}
.searchLanding .sectionContainerLanding .searchInputsLanding .singleInputLanding .textsLanding input::-moz-placeholder {
  font-size: 12px;
  opacity: 0.5x;
}
.searchLanding .sectionContainerLanding .searchInputsLanding .singleInputLanding .textsLanding input::placeholder {
  font-size: 12px;
  opacity: 0.5x;
}

.garantiaLanding .sectionContainerLanding .titlesDivLanding {
  text-align: center;
}
.garantiaLanding .sectionContainerLanding .titlesDivLanding small {
  letter-spacing: 0.8rem;
  text-transform: uppercase;
  font-size: 13px;
  color: var(--blackColor);
  line-height: 1.5rem;
}
.garantiaLanding .sectionContainerLanding .infoDivLanding {
  margin-top: 2rem;
  gap: 1.5rem;
}
.garantiaLanding .sectionContainerLanding .infoDivLanding .textDivLanding {
  gap: 1.5rem;
}
.garantiaLanding .sectionContainerLanding .infoDivLanding .textDivLanding .singleInfoLanding .numberLanding {
  background: var(--PrimaryColor);
  padding: 5px 12px;
  width: 40px;
  border-radius: 3rem;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  color: var(--whiteColor);
}
.garantiaLanding .sectionContainerLanding .infoDivLanding .imgDivLanding {
  margin: auto;
}
.garantiaLanding .sectionContainerLanding .infoDivLanding .imgDivLanding img {
  width: 100%;
  max-width: 400px;
}

.aeronavesLanding {
  background: var(--SecondaryColor);
  color: var(--whiteColor);
}
.aeronavesLanding h2 {
  color: var(--whiteColor);
}
.aeronavesLanding .titleDivLanding {
  flex-direction: column;
  text-align: center;
  gap: 1.5rem;
  justify-content: space-between;
}
.aeronavesLanding .cardsDivLanding {
  gap: 1.5rem;
  margin-top: 3rem;
}
.aeronavesLanding .cardsDivLanding .singleCardLanding {
  padding: 3rem 2rem;
  text-align: center;
}
.aeronavesLanding .cardsDivLanding .singleCardLanding img {
  border-radius: 10rem;
  gap: 1rem;
  margin: auto;
  height: 320px;
  width: 90%;
  margin-bottom: 2rem;
}

.footerLanding {
  background: var(--SecondaryColor);
  color: var(--whiteColor);
}
.footerLanding .sectionContainerLanding {
  gap: 2.5rem;
}
.footerLanding .sectionContainerLanding .gridOneLanding {
  gap: 1rem;
  justify-content: center;
  margin: auto;
}
.footerLanding .sectionContainerLanding .gridOneLanding .logoDivLanding .LogoLanding {
  width: 20%;
}
.footerLanding .sectionContainerLanding .gridOneLanding .socialIconLanding {
  margin-top: 1rem;
  gap: 1rem;
}
.footerLanding .sectionContainerLanding .gridOneLanding .socialIconLanding .iconLandingLanding {
  height: 50px;
  width: 30px;
  border-radius: 10rem;
  padding: 0.4rem;
}
.footerLanding .sectionContainerLanding .gridOneLanding .socialIconLanding .iconLandingLanding:hover {
  background: var(--PrimaryColor);
  transform: translateY(-7px);
  transition: var(--transition);
}
.footerLanding .sectionContainerLanding .footerLinksLanding {
  display: grid;
}
.footerLanding .sectionContainerLanding .footerLinksLanding .linkTitleLanding {
  color: var(--whiteColor);
  font-weight: 500;
  display: block;
  padding-bottom: 1.3rem;
}
.footerLanding .sectionContainerLanding .footerLinksLanding li a {
  display: block;
  color: var(--greyText);
  transition: var(--transition);
  padding-bottom: 0.3rem;
}
.footerLanding .sectionContainerLanding .footerLinksLanding li a:hover {
  transform: translateX(7px);
  color: var(--PrimaryColor);
}
.footerLanding .copyRightdivLanding {
  justify-content: center;
  margin-top: 1.5rem;
  padding: 1rem;
  gap: 1rem;
  border-top: 1px solid var(--greyText);
  text-align: center;
}

@media screen and (min-width: 480px) {
  .homeLanding {
    padding-top: 3rem;
  }
  .searchLanding {
    padding-top: 5rem;
  }
  .searchLanding .sectionContainerLanding {
    margin-top: 6rem;
  }
  .searchLanding .sectionContainerLanding .searchInputsLanding {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .footerLanding .sectionContainerLanding {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 570px) {
  .homeLanding .mainTextLanding {
    width: 80%;
  }
  .homeLanding .homeImagesLanding .backgroundImgLanding {
    border-radius: 15rem;
  }
  .homeLanding .homeImagesLanding .planeLanding {
    position: absolute;
    width: 95%;
    top: -8%;
  }
  .searchLanding {
    padding-top: 5rem;
  }
  .searchLanding .sectionContainerLanding {
    margin-top: 6rem;
  }
  .searchLanding .sectionContainerLanding .btnsLanding {
    border-radius: 3rem;
    flex-direction: row;
    justify-content: center;
  }
  .garantiaLanding .sectionContainerLanding .infoDivLanding .textDivLanding {
    margin-top: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .aeronavesLanding .aeronavesContainerLanding .titleDivLanding {
    flex-direction: row;
  }
  .aeronavesLanding .aeronavesContainerLanding .titleDivLanding h2 {
    text-align: left;
    max-width: 500px;
  }
  .aeronavesLanding .aeronavesContainerLanding .cardsDivLanding {
    grid-template-columns: repeat(2, 1fr);
  }
  .footerLanding .sectionContainerLanding {
    padding-top: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 680px) {
  .homeLanding .mainTextLanding {
    width: 80%;
  }
  .homeLanding .homeImagesLanding .backgroundImgLanding {
    width: 80%;
  }
  .homeLanding .homeImagesLanding .backgroundImgLanding .bgImLanding {
    height: 100%;
    width: 100%;
    border-radius: 15rem;
  }
  .homeLanding .homeImagesLanding .planeLanding {
    width: 95%;
    top: -8%;
  }
  .searchLanding {
    padding-top: 5rem;
  }
  .searchLanding .sectionContainerLanding {
    margin-top: 6rem;
  }
  .searchLanding .sectionContainerLanding .btnLandingLanding {
    border-radius: 3rem;
    flex-direction: row;
    justify-content: center;
  }
  .searchLanding .sectionContainerLanding .searchInputsLanding {
    grid-template-columns: repeat(3, 1fr);
  }
  .footerLanding .sectionContainerLanding {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 768px) {
  .navBarLanding {
    align-items: center;
  }
  .navBarLanding .navBarOneLanding .noneLanding {
    display: flex;
    gap: 1.5rem;
  }
  .navBarLanding .navBarOneLanding .noneLanding li {
    font-size: 13px;
    gap: 0.5rem;
    color: var(--textColor);
  }
  .navBarLanding .navBarOneLanding .noneLanding li:hover {
    color: var(--PrimaryColor);
  }
  .navBarLanding .navBarTwoLanding {
    justify-content: space-between;
    align-items: center;
  }
  .navBarLanding .navBarTwoLanding .logoDivLanding .LogoLanding {
    max-width: 20%;
  }
  .navBarLanding .navBarTwoLanding .menuLanding {
    flex-direction: row;
    gap: 1rem;
    margin-right: 10rem;
  }
  .navBarLanding .navBarTwoLanding .menuLanding .listItemLanding {
    padding: 0 0.5rem;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
  }
  .navBarLanding .navBarTwoLanding .menuLanding .listItemLanding:hover {
    color: var(--PrimaryColor);
    font-weight: 700;
  }
  .navBarLanding .navBarTwoLanding .btnOneLanding, .navBarLanding .navBarTwoLanding .toggleIconLanding {
    display: none;
  }
  .navBarLanding .navbar_With_BgLanding {
    padding: 1rem;
  }
  .searchLanding {
    padding: 1.5rem;
  }
  .searchLanding .sectionContainerLanding {
    margin-top: 0.3rem;
    justify-content: center;
  }
  .searchLanding .sectionContainerLanding .btnsLanding {
    width: 70%;
    border-radius: 3rem;
    flex-direction: row;
  }
  .searchLanding .sectionContainerLanding .searchInputsLanding {
    grid-template-columns: repeat(4, 1fr);
  }
  .searchLanding .sectionContainerLanding .searchInputsLanding .singleInputLanding .textsLanding input {
    width: 100%;
  }
  .garantiaLanding {
    padding: 8rem;
  }
  .garantiaLanding .sectionContainerLanding .infoDivLanding {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 5rem;
  }
  .garantiaLanding .sectionContainerLanding .infoDivLanding .textDivLanding {
    margin-top: 0rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .aeronavesLanding {
    padding: 5rem;
  }
  .aeronavesLanding .aeronavesContainerLanding {
    padding: 0 6rem;
  }
  .aeronavesLanding .aeronavesContainerLanding .titleDivLanding {
    width: 90%;
    margin: auto;
    align-items: center;
  }
  .aeronavesLanding .aeronavesContainerLanding .cardsDivLanding {
    grid-template-columns: repeat(3, 1fr);
  }
  .aeronavesLanding .aeronavesContainerLanding .cardsDivLanding img {
    width: 100%;
    height: auto;
  }
}
@media screen and (min-width: 960px) {
  .homeLanding {
    padding-top: 1.5rem;
  }
  .homeLanding .mainTextLanding {
    width: 70%;
    padding-bottom: 1.5rem;
    font-size: 22px;
    line-height: 4rem;
  }
  .homeLanding .homeImagesLanding {
    width: 80%;
    height: auto;
  }
  .searchLanding .sectionContainerLanding {
    margin-top: 6rem;
  }
  .searchLanding .sectionContainerLanding .searchInputsLanding {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  p {
    font-size: 15px;
    color: var(--greyText);
    line-height: 25px;
  }
  h4 {
    font-size: 15px;
  }
  h2 {
    font-size: 24px;
  }
  .sectionLanding {
    padding: 6rem 0 4rem;
  }
  .containerLanding {
    width: 75%;
    margin: auto;
  }
  .navBarLanding .navBarOneLanding, .navBarLanding .navBarTwoLanding {
    padding: 1.5rem 5rem;
  }
  .navBarLanding .navBarTwoLanding {
    margin-top: 1rem;
  }
  .navBarLanding .navbar_With_BgLanding {
    padding: 1rem 5rem;
    margin-top: 1rem;
  }
  .homeLanding .mainTextLanding {
    width: 60%;
    font-size: 22px;
    line-height: 4rem;
  }
  .homeLanding .homeImagesLanding .backgroundImgLanding {
    width: 90%;
    height: 300px;
  }
  .homeLanding .homeImagesLanding .backgroundImgLanding .bgImLanding {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 15rem;
  }
  .homeLanding .homeImagesLanding .planeLanding {
    width: 100%;
    top: -10%;
  }
  .searchLanding {
    padding-top: 2rem;
  }
  .searchLanding .sectionContainerLanding {
    margin-top: 1rem;
  }
  .searchLanding .sectionContainerLanding .btnsLanding {
    width: 50%;
  }
}
@media screen and (min-width: 1332px) {
  .navBarLanding .navBarTwoLanding .menuLanding {
    gap: 1rem;
  }
  .navBarLanding .navBarTwoLanding .menuLanding .listItemLanding {
    padding: 0 1.5rem;
    font-size: 14px;
  }
  .footerLanding .sectionContainerLanding {
    grid-template-columns: repeat(5, 1fr);
    font-size: smaller;
  }
}/*# sourceMappingURL=cast.css.map */