.about-container {
    max-width: 800px;
    margin: auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
    color: #333;
    text-align: center;
}

h2 {
    color: #555;
}

.process {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Asegura responsividad */
}

section {
    flex-basis: 45%; /* Ajusta según necesidad */
    margin-bottom: 20px; /* Espacio entre secciones en dispositivos pequeños */
}

ol {
    background-color: #eee;
    padding: 20px;
    border-radius: 8px;
}
